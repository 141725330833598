class Snapshot {

  static takeSnapshot(video, width, height, mirror, toBlob = true) {

    return new Promise(function(resolve, reject) {
      // Draw the 'video' element onto a temporary canvas.
      const canvas = document.createElement("canvas")
      canvas.width = width
      canvas.height = height
      const canvasContext = canvas.getContext('2d');
      if(mirror){
        canvasContext.translate(width, 0);
        canvasContext.scale(-1, 1);
      }
      canvasContext.drawImage(video, 0, 0, width, height)

      // Convert the canvas to a blob, and return the blob.
      if(toBlob) {
        canvas.toBlob(resolve, "image/png")
      } else {
        resolve(canvas.toDataURL("image/png"));
      }
    })
  }
}

export default Snapshot