<template>
	<div>
		<SnapshotNotes
			:editNotesAllowed="editNotes"
			:notes="snapshotNotes"
			:onNotesChanged="onNotesChanged"
			:canSave="canSaveNotes"
			:save="saveNotes" />
		<CoolLightBox
			ref="lightbox"
			:items="snapshotList"
			:index="lightBoxIndex"
			:class="actionClass"
			:gallery="showGallery"
			thumbsPosition="left"
			@close="lightBoxClose"
			@on-change="onLightBoxChange"
			style="background-color: rgba(3, 1, 29, 0.4); backdrop-filter: blur(8px)">
		</CoolLightBox>
		<div v-if="lightBoxIndex != null && !isViewOnly" v-show="!cropping && !annotating" class="lightbox-controls">
			<v-tooltip
				bottom
				content-class="lightbox-tooltip"
				:open-on-hover="!isTouch"
				:open-on-click="false"
				:open-on-focus="false">
				<template v-slot:activator="{ on, attrs }">
					<v-btn elevation="0" icon v-bind="attrs" v-on="on" @click="startAnnotation">
						<v-icon>icon-pencil-edit-stroke</v-icon>
					</v-btn>
				</template>
				<span>Annotate</span>
			</v-tooltip>

			<v-tooltip bottom content-class="lightbox-tooltip">
				<template v-slot:activator="{ on, attrs }">
					<v-btn elevation="0" icon v-bind="attrs" v-on="on" @click="downloadSnapshot">
						<v-icon>icon-download</v-icon>
					</v-btn>
				</template>
				<span>Download</span>
			</v-tooltip>

			<v-tooltip bottom content-class="lightbox-tooltip">
				<template v-slot:activator="{ on, attrs }">
					<v-btn elevation="0" icon v-bind="attrs" v-on="on" @click="editSnapshot">
						<div class="icon pt-1">
							<img :src="require('@/assets/liveswitch-caption.svg')" alt="Processing" />
						</div>
					</v-btn>
				</template>
				<span>Add Caption</span>
			</v-tooltip>

			<v-tooltip bottom content-class="lightbox-tooltip">
				<template v-slot:activator="{ on, attrs }">
					<v-btn elevation="0" icon v-bind="attrs" v-on="on" @click="cropSnapshot">
						<v-icon>icon-crop</v-icon>
					</v-btn>
				</template>
				<span>Crop</span>
			</v-tooltip>

			<v-tooltip bottom content-class="lightbox-tooltip">
				<template v-slot:activator="{ on, attrs }">
					<v-btn elevation="0" icon v-bind="attrs" v-on="on" @click="() => deleteSnapshot(lightBoxIndex)">
						<v-icon color="red">icon-trash-full-stroke</v-icon>
					</v-btn>
				</template>
				<span>Delete</span>
			</v-tooltip>
		</div>
		<div v-if="annotating" class="annotation-overlay"></div>
		<div v-if="annotating" class="annotation-controls">
			<v-overlay :absolute="true" :opacity="0.5" :value="savingAnnotation">
				<v-progress-circular indeterminate color="primary"></v-progress-circular>
				Saving Image...
			</v-overlay>
			<SnapshotAnnotation
				:backgroundImage="backgroundImage"
				:backgroundImageCaption="backgroundImageCaption"
				@cancel-annotation="cancelAnnotation"
				@save-annotation="saveAnnotation">
			</SnapshotAnnotation>
		</div>
		<div v-show="cropping" class="crop-controls">
			<v-overlay :absolute="true" :opacity="0.32" :value="savingCrop">
				<v-progress-circular indeterminate color="primary"></v-progress-circular>
				Cropping Image...
			</v-overlay>
			<div id="crop-container"></div>
			<div>
				<v-btn elevation="0" color="#fff" @click="cancelCrop"> Back </v-btn>
				<v-btn elevation="0" color="#fff" @click="saveCrop"> Apply </v-btn>
			</div>
		</div>
	</div>
</template>

<script>
	import CoolLightBox from "vue-cool-lightbox";
	import api from "../api";
	import timezones from "@/helpers/timezones";
	import DeviceUtility from "@/helpers/device";
	import Cropper from "cropperjs";

	export default {
		components: {
			CoolLightBox,
		},
		props: {
			snapshots: {
				type: Array,
				required: true,
			},
			bulkSelect: {
				type: Boolean,
				required: false,
			},
			lightboxId: {
				type: String,
				required: false,
			},
			showGallery: {
				type: Boolean,
				default: true,
			},
			isViewOnly: {
				type: Boolean,
				default: false,
			},
		},
		data() {
			return {
				saving: false,
				editNotes: false,
				snapshotNotes: "",
				snapshotList: [],
				canSaveNotes: false,
				lightBoxIndex: null,
				cropping: false,
				annotating: false,
				savingAnnotation: false,
				savingCrop: false,
				isTouch: false,
				dirty: false,
				isLightboxThumbsOpen: false,
				isUnauthenticated: false,
				backgroundImage: "",
				backgroundImageCaption: {
					title: "",
					description: "",
				},
			};
		},
		async mounted() {
			this.snapshotList = this.snapshots;
			this.$watch(
				() => this.$refs.lightbox?.showThumbs,
				(newVal) => {
					this.isLightboxThumbsOpen = newVal;
				}
			);
		},
		computed: {
			actionClass() {
				if (this.cropping) {
					return "cropping";
				} else if (this.annotating) {
					return "annotating";
				} else {
					return "";
				}
			},
		},
		methods: {
			hideThumbnailTray() {
				this.$refs.lightbox.showThumbs = false;
			},
			openLightBox(id) {
				if (!id || !this.snapshotList || this.snapshotList.length === 0) {
					return;
				}
				this.lightBoxIndex = this.snapshotList.findIndex((s) => s.id === id);
				if (this.lightBoxIndex < 0) {
					return;
				}
				this.onLightBoxChange(this.lightBoxIndex);

				if (DeviceUtility.isMobile || DeviceUtility.getDeviceType() === "tablet") {
					this.$refs.lightbox.canZoom = false;
				}

				// if we are in mobile then we have to close the thumbnail tray after selecting a new image
				if (this.isLightboxThumbsOpen && window.innerWidth < 960) {
					this.$refs.lightbox.showThumbs = false;
				}
			},
			async saveNotes(snapshotNotes) {
				const snapshot = this.snapshots[this.lightBoxIndex];

				let request = { notes: snapshotNotes };
				await api.updateSnapshot(snapshot.id, request);

				snapshot.description = snapshotNotes;
				this.editNotes = false;
				this.snapshotNotes = "";
				this.canSaveNotes = false;
			},
			onNotesChanged(notes) {
				const snapshot = this.snapshots[this.lightBoxIndex];
				this.canSaveNotes = notes != snapshot.description;
			},
			lightBoxClose() {
				this.cancelCrop();
				this.cancelAnnotation();
				this.editNotes = false;
				this.snapshotNotes = "";
				this.canSaveNotes = false;
				this.lightBoxIndex = null;
			},
			onLightBoxChange(index) {
				this.lightBoxIndex = index;
			},
			startAnnotation() {
				const currentSlide = document.querySelector(".cool-lightbox__slide--current").firstChild;
				this.backgroundImage = currentSlide.firstChild;
				this.backgroundImageCaption.title = this.snapshotList[this.lightBoxIndex].title;
				this.backgroundImageCaption.description = this.snapshotList[this.lightBoxIndex].description;
				currentSlide.firstChild.style.display = "none";
				this.annotating = true;
			},
			async saveAnnotation(blob) {
				try {
					if (blob) {
						this.savingAnnotation = true;
						const snapshot = this.snapshotList[this.lightBoxIndex];
						const annotatedSnapshot = await api.uploadSnapshot(snapshot.conversationId, blob, snapshot.id);
						this.annotating = false;
						this.snapshotList.splice(this.lightBoxIndex + 1, 0, {
							id: annotatedSnapshot.id,
							conversationId: snapshot.conversationId,
							src: annotatedSnapshot.uploadResult.signedUrl,
							description: annotatedSnapshot.notes ?? "",
							title: timezones.formatDate(annotatedSnapshot.createdAt),
							createdAt: annotatedSnapshot.createdAt,
						});
						const annotatedSnapshotIndex = this.lightBoxIndex + 1;
						this.lightBoxIndex = null;
						this.$nextTick(() => {
							this.lightBoxIndex = annotatedSnapshotIndex;
							if (this?.$refs?.lightbox) {
								this.$refs.lightbox.isZooming = false;
							}
						});
					}
				} catch (e) {
					console.error(e);
				} finally {
					this.cancelAnnotation();
				}
			},

			cancelAnnotation() {
				this.annotating = false;
				this.savingAnnotation = false;
				const currentSlide = document.querySelector(".cool-lightbox__slide--current").firstChild;
				currentSlide.firstChild.style.display = "block";
			},
			downloadSnapshot() {
				const snapshot = this.snapshotList[this.lightBoxIndex];
				const url = snapshot.src;
				const a = document.createElement("a");
				a.href = url;
				let fileName = url.substring(url.lastIndexOf("/") + 1);
				fileName = fileName.replace("snapshot", "file");
				a.download = fileName;
				a.click();
			},
			editSnapshot() {
				const snapshot = this.snapshotList[this.lightBoxIndex];
				this.snapshotNotes = snapshot.description;
				this.editNotes = true;
			},
			cropSnapshot() {
				try {
					this.cropper.destroy();
				} catch (error) {
					// brute force
				}
				this.cropper = {};

				const currentSlide = document.querySelector(".cool-lightbox__slide--current").firstChild;
				const image = currentSlide.firstChild.cloneNode();
				currentSlide.firstChild.style.display = "none";
				const wrapper = document.getElementById("crop-container");
				wrapper.appendChild(image);
				this.cropper = new Cropper(image, {
					scalable: true,
					checkCrossOrigin: true,
					checkOrientation: false,
					viewMode: 1,
					ready: function () {
						this.cropper.setCropBoxData(this.cropBoxData).setCanvasData(this.canvasData);
					},
				});
				this.cropping = true;
			},
			cancelCrop() {
				try {
					this.cropping = false;
					const currentSlide = document.querySelector(".cool-lightbox__slide--current").firstChild;
					currentSlide.firstChild.style.display = "block";
					const wrapper = document.getElementById("crop-container");
					wrapper.innerHTML = "";
					try {
						this.cropper.destroy();
					} catch (error) {
						// brute force
					}
					this.savingCrop = false;
				} catch (err) {
					console.error(err);
				}
			},
			saveCrop() {
				const snapshot = this.snapshotList[this.lightBoxIndex];
				this.savingCrop = true;
				this.cropper.getCroppedCanvas().toBlob(async (blob) => {
					if (blob == null) {
						await this.$root.$dangerzone.open("Error", `The cropped snapshot is too small.`, "Close");
						this.savingCrop = false;
						return;
					}
					const cropped = await api.uploadSnapshot(snapshot.conversationId, blob, snapshot.id);
					this.snapshotList.splice(this.lightBoxIndex + 1, 0, {
						id: cropped.id,
						conversationId: snapshot.conversationId,
						src: cropped.uploadResult.signedUrl,
						description: cropped.notes ?? "",
						title: timezones.formatDate(cropped.createdAt),
						createdAt: cropped.createdAt,
					});
					this.cancelCrop();
					const newIndex = this.lightBoxIndex + 1;
					this.lightBoxIndex = null;
					this.$nextTick(() => {
						this.lightBoxIndex = newIndex;
						if (this?.$refs?.lightbox) {
							this.$refs.lightbox.isZooming = false;
						}
					});
				});
			},
			async deleteSnapshot(indexToDelete) {
				const index = indexToDelete ?? this.lightBoxIndex;
				if (await this.$root.$dangerzone.open("Delete Photo", `This will delete the photo.`)) {
					await api.deleteSnapshot(this.snapshotList[index].id);
					this.snapshotList.splice(index, 1);
					if (this.snapshotList.length > 0) {
						this.lightBoxIndex = null;
						// this lets the lightbox refresh its items properly.
						// if we go from index 0 to 0 the image stays the same even though we just deleted it
						this.$nextTick(() => {
							this.lightBoxIndex = index === 0 ? 0 : Math.max(0, index - 1);
							if (this?.$refs?.lightbox) {
								this.$refs.lightbox.isZooming = false;
							}
						});
					} else {
						if (this?.$refs?.lightbox) {
							this.$refs.lightbox.close();
						}
					}
				}
			},
			reset() {
				this.$router.push({ name: "snapshots" });
			},
			save() {
				this.saving = true;
				this.$store.dispatch("saveSnapshots", this.snapshotList).then(() => {
					this.saving = false;
					this.dirty = false;
				});
			},
		},
		watch: {
			snapshots: {
				handler(newVal) {
					this.snapshotList = newVal;
				},
				deep: true,
			},
			isLightboxThumbsOpen: {
				handler(newVal) {
					this.$emit("update:isLightboxThumbsOpen", newVal);
				},
			},
			lightboxId: {
				handler(newVal) {
					this.openLightBox(newVal);
				},
			},
		},
	};
</script>

<style scoped>
	.lightbox-controls {
		position: fixed;
		width: 70%;
		display: flex;
		justify-content: center;
		z-index: 10000;
		top: 0;
		left: 15%;
	}

	.lightbox-controls > button.theme--light.v-btn.v-btn--icon,
	.cool-lightbox-toolbar > button.cool-lightbox-toolbar__btn,
	.cool-lightbox__navigation > button.cool-lightbox-button > div.cool-lightbox-button__icon {
		color: #fff;
		background-color: #000;
		border-radius: 100px;
		height: 40px;
		width: 40px;
		margin: 10px 3px;
	}

	.cool-lightbox .cool-lightbox-button.cool-lightbox-button--next {
		right: 0px;
		padding: 0;
	}

	.cool-lightbox .cool-lightbox-button.cool-lightbox-button--prev {
		left: 20px;
		padding: 0;
	}

	.cool-lightbox.annotating {
		pointer-events: none;
	}
	.lightbox-controls > button.theme--light.v-btn.v-btn--icon:last-child {
		color: #ec5d43;
	}

	.lightbox-tooltip {
		background-color: #212121;
		font-size: 10px;
		line-height: 12px;
		padding: 5px 8px;
	}

	.cool-lightbox-toolbar {
		display: flex;
		width: 100%;
		justify-content: space-between;
		padding: 10px;
		padding-top: 0;
		margin-right: 10px;
	}

	.cool-lightbox-toolbar > button:first-child {
		display: none;
	}

	.cool-lightbox-toolbar > button.cool-lightbox-toolbar__btn:nth-child(2) {
		margin-left: 20px;
	}

	.cool-lightbox-toolbar:has(> button:only-child) {
		justify-content: flex-end;
	}

	.cool-lightbox-toolbar > button:only-child {
		display: block;
	}

	.cool-lightbox--show-thumbs.cool-lightbox--thumbs-left > .cool-lightbox-thumbs {
		left: 0;
	}

	.cool-lightbox .cool-lightbox__inner {
		left: 0;
	}

	.cool-lightbox--show-thumbs.cool-lightbox--thumbs-left > .cool-lightbox__inner {
		left: 280px;
	}

	.cool-lightbox--show-thumbs.cool-lightbox--thumbs-left > .cool-lightbox-thumbs {
		width: 280px;
		background-color: #000;
	}

	.cool-lightbox--show-thumbs.cool-lightbox--thumbs-left ~ .lightbox-controls {
		left: calc(10% + 280px);
		width: calc(80% - 280px);
	}

	.cool-lightbox .cool-lightbox-thumbs .cool-lightbox-thumbs__list {
		display: none;
	}

	.cool-lightbox .cool-lightbox-thumbs .cool-lightbox-thumbs__list .cool-lightbox__thumb:before {
		border-color: rgba(0, 112, 255, 1);
	}

	.cool-lightbox.cropping > .cool-lightbox__inner > .cool-lightbox-toolbar,
	.cool-lightbox.cropping > .cool-lightbox__inner > .cool-lightbox__navigation {
		display: none;
	}

	.cool-lightbox.annotating .cool-lightbox-caption,
	.cool-lightbox.annotating > .cool-lightbox__inner > .cool-lightbox-toolbar,
	.cool-lightbox.annotating > .cool-lightbox__inner > .cool-lightbox__navigation {
		display: none;
	}

	.snapshots.fixed {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 10010;
		width: 280px;
		height: 100vh;
		overflow-y: scroll;
	}

	.snapshots.fixed button {
		background-color: #1e2128 !important;
		color: #fff;
		font-size: 12px;
	}

	.snapshots.fixed > .snapshot-controls {
		flex-direction: column;
	}

	.snapshots.fixed > .snapshot-controls > div:nth-child(2) > button {
		background-color: #000 !important;
	}

	.snapshots.fixed > .snapshot-controls > div:nth-child(2) > button:first-child {
		color: #fff !important;
	}

	.cool-lightbox-caption {
		height: 130px;
		overflow-y: scroll;
	}

	.cool-lightbox-caption > p {
		max-width: 100%;
		overflow-wrap: break-word;
		margin: auto;
	}

	/* this section is when the nav bar moves to the bottom of the screen */
	@media (max-width: 959px) {
		.snapshots.fixed {
			width: 100%;
			z-index: 10009;
		}

		.cool-lightbox--show-thumbs.cool-lightbox--thumbs-left > .cool-lightbox-thumbs {
			width: 100%;
			z-index: 100019;
		}

		.cool-lightbox--show-thumbs.cool-lightbox--thumbs-left ~ .lightbox-controls {
			display: none;
		}
	}
</style>
