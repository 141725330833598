import moment from "moment-timezone";

let timezones = {
	getTimezones: function () {
		const zones = [];
		const countries = moment.tz.countries();

		for (const country of countries) {
			moment.tz.zonesForCountry(country).reduce((set, zone) => zones.push(zone));
		}
		return zones;
	},
	guess: function () {
		let tz = moment.tz.guess();
		// console.log(tz)
		let allTz = this.getTimezones();
		// console.log(allTz)
		if (allTz.indexOf(tz) > -1) {
			// console.log('hit')
			return tz;
		}
		if (allTz.indexOf("America/New_York") > -1) {
			return "America/New_York";
		}
		return allTz[0];
	},
	formatDate: function (value, format = "MM/DD/YYYY h:mm a z") {
		if (value) {
			// TODO: i18n
			return moment.tz(String(value), "YYYY-MM-DD HH:mm:SS.ii ZZ", moment.tz.guess()).format(format);
		}
	},
};
export default timezones;
