<template>
	<v-dialog v-model="dialog" :max-width="500" @keydown.esc="cancel" @input="dialogOpened">
		<v-card>
			<v-card-title> Contact Support </v-card-title>
			<v-card-text>
				<p>
					Our LiveSwitch Customer Success managers are here to help. Submit your question or feedback below,
					and please include the best phone number to reach you. You can also reach us via the email and phone
					number below.
				</p>

				<div>Email: <a href="mailto:help@liveswitch.com" target="_blank">help@liveswitch.com</a></div>
				<div>Phone: <a href="tel:+1 844-587-9824" target="_blank">+1 844-587-9824</a></div>
				<v-textarea
					class="mt-4"
					outlined
					hide-details
					rows="5"
					v-model="message"
					:disabled="sending"
					label="Contact Us"
					:maxLength="2000" />
			</v-card-text>
			<v-card-actions class="pt-0">
				<v-spacer></v-spacer>
				<v-btn rounded elevation="0" @click.native="cancel">Back</v-btn>
				<v-btn
					rounded
					elevation="0"
					color="primary"
					@click.native="submit"
					:loading="sending"
					:disabled="sending || !validMessage"
					>Send</v-btn
				>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
	import api from "../api";
	import * as Sentry from "@sentry/vue";

	export default {
		data: () => ({
			dialog: false,
			resolve: null,
			reject: null,
			message: null,
			sending: false,
		}),
		async mounted() {},
		computed: {
			validMessage() {
				if (!this.message || this.message.trim().length == 0) {
					return false;
				}

				return true;
			},
		},
		methods: {
			open() {
				this.dialog = true;
				return new Promise((resolve, reject) => {
					this.resolve = resolve;
					this.reject = reject;
				});
			},
			dialogOpened() {
				this.message = null;
			},
			async submit() {
				try {
					this.sending = true;
					const eventId = Sentry.captureMessage("User Feedback");
					const sentryUserFeedback = {
						event_id: eventId,
						message: this.message,
					};
					Sentry.captureUserFeedback(sentryUserFeedback);
					await api.submitSupportRequest(this.message);
					this.resolve(true);
					this.$root.$toast.open(
						null,
						"Your support request has been submitted, and someone from our team will get back to you right away."
					);
				} catch (e) {
					this.$root.$dangerzone.open("Error", e.message, "OK");
				} finally {
					this.dialog = false;
					this.message = null;
					this.sending = false;
				}
			},
			cancel() {
				this.resolve(false);
				this.dialog = false;
				this.message = null;
			},
		},
	};
</script>
<style scoped>
	a {
		text-decoration: none;
	}
</style>
