<template>
	<v-card :class="cardClass()">
		<v-card-text>
			<div v-show="currentState == constants.SCHEDULER_STATE.SCHEDULE">
				<div class="header schedule mb-0">
					<h2><span class="action-header-schedule">Schedule</span> a Live Video Call.</h2>

					<v-menu
						v-model="timeZonesOpen"
						:close-on-content-click="true"
						:close-on-click="true"
						:offset-y="true"
						:min-width="300"
						:max-height="400"
						tile
						left
						bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								small
								rounded
								elevation="0"
								:class="{ open: timeZonesOpen }"
								:ripple="false"
								v-bind="attrs"
								v-on="on">
								Time Zone
							</v-btn>
						</template>

						<v-list>
							<v-list-item
								v-for="zone in getTimezones()"
								:key="zone.original"
								link
								@click="selectTimeZone(zone.original)">
								<v-list-item-title v-text="zone.formatted"></v-list-item-title>
							</v-list-item>
						</v-list>
					</v-menu>
				</div>
				<div class="d-flex timezone mb-2 mt-2">
					{{ timeZoneFormatted }}
				</div>
				<div class="date-container">
					<div class="d-flex flex">
						<button class="btn-pill date-pill" @click="selectDate">
							{{ selectedDate }}
						</button>
					</div>
					<div class="d-flex flex time-pill">
						<button class="btn-pill" @click="activateTimePickerMenu">
							{{ standardTime ? standardTime : "Select a Time" }}
						</button>
						<v-select
							ref="timePicker"
							class="time-picker"
							style="position: absolute; top: 50px; visibility: hidden"
							outlined
							dense
							hide-details
							v-model="scheduledTime"
							@input="timeSelected"
							:items="times"
							item-text="text"
							item-value="value"
							item-disabled="disabled"
							:menu-props="{
								auto: true,
								closeOnClick: true,
								closeOnContentClick: true,
								contentClass: 'time-picker-menu',
							}">
						</v-select>
					</div>
				</div>
				<v-form v-model="form" :autocomplete="autocomplete">
					<!--Enter a phone number-->
					<div @paste.prevent="pasteEvent" class="mb-3" style="position: relative">
						<vue-tel-input
							:autocomplete="autocomplete"
							tabindex="0"
							ref="phoneInput"
							:border-radius="0"
							:validCharactersOnly="true"
							valid-color="#000000"
							v-bind="vueTelProps"
							:disabled="disablePhoneNumberEdit"
							@validate="validate"
							@input="matchPhone"
							@country-changed="matchPhone"
							@blur="phoneBlur"
							@focus="phoneFocus"
							class="field-border-radius"
							:class="{
								'error--text': showPhoneFieldErrorState,
							}"
							v-model="phone" />

						<!--Reset-->
						<span v-if="allowReset" class="reset-span">
							<a href="#" @click="reset()">Reset</a>
						</span>

						<v-autocomplete
							:autocomplete="autocomplete"
							class="customer-phone-search autocomplete hide-chevron"
							ref="customerPhoneSearch"
							:menu-props="{ value: phoneSearchResultsOpen }"
							outlined
							dense
							hide-details
							no-data-text="No contacts found"
							counter="60"
							v-model="phoneSearch"
							item-text="searchLabel"
							item-value="phone"
							return-object
							@input="customerPhoneSelected"
							:items="customerItems"
							:maxLength="60">
							<template v-slot:item="{ item }">
								<v-list-item-content>
									<v-list-item-title v-if="item.name">{{ item.name }}</v-list-item-title>
									<v-list-item-subtitle v-if="item.phone"
										><span>{{ item.phone }}</span></v-list-item-subtitle
									>
									<v-list-item-subtitle v-if="item.email"
										><span>{{ item.email }}</span></v-list-item-subtitle
									>
								</v-list-item-content>
							</template>
						</v-autocomplete>
					</div>
					<!--Contact name-->
					<v-autocomplete
						v-if="!phone || selectedCustomer"
						:autocomplete="autocomplete"
						ref="customers"
						class="autocomplete hide-chevron"
						dense
						hide-details
						outlined
						label="Contact"
						no-data-text="No contacts found"
						counter="60"
						clearable
						v-model="selectedCustomer"
						item-text="searchLabel"
						item-value="id"
						return-object
						cache-items
						@input="customerSelected"
						@click:clear="clearCustomer"
						:readonly="selectedCustomer != null || validCustomer || disablePhoneNumberEdit"
						:items="customerItems"
						:maxLength="60"
						:search-input.sync="searchCustomers">
						<template v-slot:selection="{ attr, on, item, selected }">
							<span v-if="item.name">{{ item.name }}</span>
						</template>
						<template v-slot:item="{ item }">
							<v-list-item-content>
								<v-list-item-title v-if="item.name">{{ item.name }}</v-list-item-title>
								<v-list-item-subtitle v-if="item.phone"
									><span>{{ item.phone }}</span></v-list-item-subtitle
								>
								<v-list-item-subtitle v-if="item.email"
									><span>{{ item.email }}</span></v-list-item-subtitle
								>
							</v-list-item-content>
						</template>
					</v-autocomplete>

					<!--v-if="(!validCustomer && phone && validPhone) || (phone && !validPhone)"-->

					<v-text-field
						v-else
						:autocomplete="autocomplete"
						v-model="name"
						ref="name"
						:disabled="disablePhoneNumberEdit"
						hide-details
						label="Contact"
						outlined
						dense
						clearable
						:maxLength="56"
						class="field-border-radius"
						:class="allowReset ? 'reset' : ''" />

					<ProjectAutoComplete :selected-project="newSelectedProject" @change="updateProject" />

					<!--Add a message-->
					<v-textarea
						:autocomplete="autocomplete"
						ref="message"
						rows="3"
						v-model="notificationTemplate"
						:placeholder="defaultNotificationTemplate"
						:disabled="disablePhoneNumberEdit"
						hide-details
						label="Message"
						outlined
						counter="500"
						:maxLength="500"
						:counter-value="countCharacters"
						class="field-border-radius"
						@change="templateChanged" />

					<div>
						<v-text-field
							:autocomplete="autocomplete"
							dense
							:disabled="emailEditDisabled"
							class="field-border-radius email-invite-input"
							outlined
							label="Include Email Invitation"
							v-model="email"
							ref="email"
							:required="includeEmailInvitation"
							:rules="emailRules"
							hide-details="auto"
							validate-on-blur
							clearable
							@input="emailChanged">
							<template v-slot:prepend-inner style="margin-top: 4px">
								<v-checkbox
									v-model="includeEmailInvitation"
									ref="includeEmailInvitation"
									hide-details
									class="shrink mr-2 mt-0 mb-0 email-invite-checkbox"></v-checkbox>
							</template>
						</v-text-field>
					</div>

					<div>
						<v-combobox
							:autocomplete="autocomplete"
							dense
							hide-details
							outlined
							label="Assign to User"
							no-data-text="Start typing to find a user"
							counter="60"
							clearable
							v-model="shareUser"
							item-text="name"
							item-value="id"
							return-object
							class="hide-chevron"
							prepend-inner-icon="icon-user-single"
							:items="teamMembers"
							:maxLength="60">
							<template v-slot:selection="{ attr, on, item, selected }">
								<span v-if="item.name">{{ item.name }}</span>
								<span v-else-if="item.phone">{{ item.phone }}</span>
								<span v-else-if="item.email">{{ item.email }}</span>
							</template>
							<template v-slot:item="{ item }">
								<v-list-item-content v-if="!item.edit">
									<v-list-item-title v-if="item.name">{{ item.name }}</v-list-item-title>
									<v-list-item-subtitle v-if="item.phone"
										><span>{{ item.phone }}</span></v-list-item-subtitle
									>
									<v-list-item-subtitle v-if="item.email"
										><span>{{ item.email }}</span></v-list-item-subtitle
									>
								</v-list-item-content>
							</template>
						</v-combobox>
					</div>
				</v-form>
			</div>
			<div v-show="currentState == constants.SCHEDULER_STATE.SELECT_DATE">
				<div class="header">
					<h2>Choose a Date:</h2>
				</div>
				<v-row justify="center">
					<v-date-picker
						v-model="scheduledDate"
						header-color="var(--schedule-picker)"
						full-width
						:min="minimumDate"
						@change="dateSelected"></v-date-picker>
				</v-row>
			</div>
			<AppointmentConfirmation
				v-if="appointment"
				:appointment="appointment"
				:timeZone="timeZone"
				:name="name"
				:phone="phone"
				v-show="currentState == constants.SCHEDULER_STATE.CONFIRMATION"></AppointmentConfirmation>
		</v-card-text>

		<v-card-actions>
			<!--Close-->
			<v-btn
				v-if="
					currentState == constants.SCHEDULER_STATE.SCHEDULE ||
					currentState == constants.SCHEDULER_STATE.CONFIRMATION
				"
				text
				outlined
				elevation="0"
				@click="close()">
				{{ this.currentState == constants.SCHEDULER_STATE.CONFIRMATION ? "Close" : "Back" }}
			</v-btn>

			<!--Start-->
			<v-btn
				color="primary"
				elevation="0"
				:loading="saving"
				:disabled="startButtonDisabled()"
				v-if="
					currentState == constants.SCHEDULER_STATE.SCHEDULE &&
					currentState != constants.SCHEDULER_STATE.CONFIRMATION
				"
				@click="schedule()">
				Schedule
			</v-btn>

			<!-- Back -->
			<v-btn
				class="back"
				elevation="0"
				:disabled="invalidDate"
				v-if="
					currentState != constants.SCHEDULER_STATE.SCHEDULE &&
					currentState != constants.SCHEDULER_STATE.CONFIRMATION
				"
				@click="currentState = constants.SCHEDULER_STATE.SCHEDULE">
				Back
			</v-btn>

			<!-- Join -->
			<v-btn
				color="primary"
				elevation="0"
				:disabled="invalidDate"
				v-if="currentState === constants.SCHEDULER_STATE.CONFIRMATION"
				@click="join">
				<v-icon left> icon-video </v-icon>
				Join
			</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
	import api from "../api";
	import "../helpers/emoji";
	import Projects from "../helpers/projects";
	import tokenHandler from "../helpers/tokenHandler";
	import moment from "moment-timezone";
	import constants from "../api/constants";
	import Dialogs from "../helpers/dialogs";
	import AppointmentConfirmation from "./AppointmentConfirmation.vue";
	import { EventBus } from "../helpers/eventBus";
	import ProjectAutoComplete from "./ProjectAutoComplete.vue";
	import DeviceUtility from "@/helpers/device";

	export default {
		components: {
			AppointmentConfirmation,
			ProjectAutoComplete,
		},
		props: {
			projectId: String,
			conversationType: String, //sync or async
			customerId: String,
			actionText: String,
			actioningText: String,
			allowJoin: Boolean,
			buttonIcon: String,
			showHeader: Boolean,
		},
		components: {
			AppointmentConfirmation,
		},
		data() {
			return {
				showPhoneFieldErrorState: false,
				autocomplete: Math.random(),
				saving: false,
				form: false,
				currentTab: null,
				vueTelProps: window.VueTelProps,
				starting: false,
				validPhone: false,
				autoSetName: false,
				selectedCustomerId: null,
				autoSetNameValue: "",
				emailDisabled: true,
				phone: "",
				phoneSearch: "",
				email: "",
				emptyEmail: true,
				name: "",
				includeEmailInvitation: false,
				meetingUrl: "",
				actioningTextOverride: "",
				organization: {},
				autoStartRecordings: true,
				notificationTemplate: "",
				defaultNotificationTemplate: "",
				workingNotificationTemplate: "",
				currentDate: moment(),
				minimumDate: moment().format("YYYY-MM-DD"),
				scheduledDate: moment().format("YYYY-MM-DD"),
				minimumTime: moment().format("HH:mm"),
				startTime: null,
				scheduledTime: "",
				currentPeriod: moment().format("a"),
				currentState: constants.SCHEDULER_STATE.SCHEDULE,
				appointment: {},
				conversation: {},
				timeZone: null,
				timeZoneFormatted: null,
				constants: constants,
				shareUser: null,
				teamMembers: [],
				me: null,
				customers: [],
				selectedCustomer: {},
				validCustomer: true,
				searchCustomers: null,
				phoneSearchResultsOpen: false,
				customerJustSelected: false,
				invalidDate: false,
				timeZonesOpen: false,
				previousDay: null,
				previousDate: null,
				previousScheduledDate: null,
				previousScheduledTime: null,
				previousTimeZone: null,
				times: [],
				newSelectedProject: null,
				projectSelectItems: [],
			};
		},
		computed: {
			dynamicActioningText() {
				if (this.actioningTextOverride) {
					return this.actioningTextOverride;
				}
				return this.starting ? this.actioningText : this.actionText;
			},
			dynamicName() {
				return this.nameOverride ?? this.name;
			},
			disablePhoneNumberEdit() {
				return !!this.meetingUrl;
			},
			allowReset() {
				return this.meetingUrl;
			},
			invitationEmail() {
				return this.includeEmailInvitation ? this.email : "";
			},
			emailEditDisabled() {
				return this.emailDisabled || !this.includeEmailInvitation || this.disablePhoneNumberEdit;
			},
			standardTime() {
				if (!this.scheduledTime) {
					return "";
				}

				return this.scheduledTime;
			},
			scheduledName() {
				return this.name ?? this.phone;
			},
			selectedDate() {
				if (!this.scheduledDate) {
					return this.currentDate.format("ddd, MMM DD");
				}

				return moment(this.scheduledDate).format("ddd, MMM DD");
			},
			scheduledDisplayTime() {
				if (!this.appointment) {
					return "";
				}

				const start = moment(this.appointment.startAt);
				const end = moment(this.appointment.endAt);
				const display = `${start.format("h:mma")} - ${end.format("h:mma")}, ${start.format("dddd, MMM Do")}`;
				return display;
			},
			icsText() {
				if (!this.appointment) {
					return "";
				}

				let icsText = `data:text/calendar;charset=utf-8,${encodeURIComponent(this.appointment.icsText)}`;
				return icsText;
			},
			customerItems() {
				return this.customers
					.map((customer) => {
						let name = `${customer.firstname} ${customer.lastname}`.trim();
						let phone = null;
						let email = null;
						let searchLabel = name;

						if (name === "") {
							name = null;
						}

						if (customer.PhoneNumbers?.length > 0) {
							phone = customer.PhoneNumbers[0].phoneNumber;
							searchLabel = `${searchLabel} ${phone.replaceAll(" ", "")}`;
						}

						if (customer.EmailAddresses?.length > 0) {
							email = customer.EmailAddresses[0].emailAddress;
							searchLabel = `${searchLabel} ${email}`;
						}

						return Object.assign({}, customer, { name, phone, email, searchLabel });
					})
					.filter((x) => x.searchLabel.trim() !== "");
			},
			emailRules() {
				if (!this.includeEmailInvitation) {
					return [];
				}
				//email is getting set to null because of clearable in v-text-field
				if (!this.email) {
					return [];
				}

				const rules = [];
				rules.push((v) => v.length <= 125 || "Please enter a shorter email.");
				rules.push((v) => {
					let err = "Please enter a valid email or un-check the Include Email Invitation option.";
					if (!v) {
						return err;
					}
					let isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v);
					if (!isValid) {
						return err;
					}
					return true;
				});

				return rules;
			},
		},
		async mounted() {
			this.me = await api.getMe(true, true, true);
			this.reset();
			this.organization = this.$root.$organization; //JSON.parse(localStorage.getItem("org"));
			this.autoStartRecordings = this.organization.autoStartRecordings;
			this.notificationTemplate = this.organization.notificationTemplateScheduled;
			this.workingNotificationTemplate = this.notificationTemplate;
			this.previousScheduledDate = this.scheduledDate;
			this.timeZone = moment.tz.guess();
			this.timeZoneFormatted = `(GMT${moment.tz(this.timeZone).format("Z")}) ${this.timeZone} ${moment
				.tz(this.timeZone)
				.format("z")}`.replaceAll("_", " ");
			this.populateTimes();
			this.updateTemplate();
			this.defaultNotificationTemplate = this.notificationTemplate;
			const start = moment();
			const remainder = 15 - (start.minute() % 15);
			this.startTime = moment(start).add(remainder, "minutes");
			console.log(this.startTime.toString());
			this.scheduledTime = this.startTime.format("hh:mm A");
			console.log(this.scheduledTime);
			if (this.projectId) {
				const result = await api.getProject(this.projectId);
				this.newSelectedProject = result;
			}
			this.customers = await Dialogs.getCustomers();
			if (this.customerId) {
				const result = await api.getCustomers({
					where: {
						id: this.customerId,
					},
				});

				if (result.count > 0) {
					this.setCustomer(result.rows[0]);
				}
			}

			await this.loadTeamMembers();
		},
		methods: {
			async updateProject(project) {
				this.newSelectedProject = project;
			},
			isAdmin() {
				if (this.me?.account.roles[0].name.toLowerCase() === "user") {
					return false;
				}
				return true;
			},
			getTimezones() {
				const formatted = [];
				const zones = moment.tz.names();

				for (var i in zones) {
					const offset = moment.tz(zones[i]).utcOffset();
					formatted.push({
						original: zones[i],
						offset: offset,
						formatted: `(GMT${moment.tz(zones[i]).format("Z")}) ${zones[i]}`,
					});
				}

				// TODO: MK - Leaving this for a future release. Need to discuss UX
				// const sorted = formatted.sort((a, b) => {
				// 	return a.offset < b.offset ? -1 : 1;
				// });

				return formatted;
			},
			populateTimes() {
				let hours, minutes, ampm;
				this.times = [];

				for (var i = 0; i <= 1440; i += 15) {
					hours = Math.floor(i / 60);
					minutes = i % 60;

					if (minutes < 10) {
						minutes = "0" + minutes; // adding leading zero
					}

					ampm = hours % 24 < 12 ? "AM" : "PM";
					hours = hours % 12;

					if (hours === 0) {
						hours = 12;
					}

					let disabled = false;

					// Minimum time will only be set if the date is set to the current day

					if (this.minimumTime) {
						const minTimeHours = Number.parseInt(this.minimumTime.split(":")[0]);
						const minTimeMinutes = Number.parseInt(this.minimumTime.split(":")[1]);
						const minTime = minTimeHours * 60 + minTimeMinutes;
						disabled = i < minTime;
					}

					const time = `${hours}:${minutes} ${ampm}`;
					this.times.push({
						text: time,
						value: time,
						disabled: disabled,
					});
				}
			},
			selectTimeZone(zone) {
				this.previousTimeZone = moment()
					.tz(this.timeZone ?? moment.tz.guess())
					.format("z");
				this.timeZone = zone;
				this.timeZoneFormatted = `(GMT${moment.tz(this.timeZone).format("Z")}) ${this.timeZone} ${moment
					.tz(this.timeZone)
					.format("z")}`.replaceAll("_", " ");
				this.updateMinimumDate(this.scheduledDate);
				this.updateTemplate();
			},
			updateMinimumDate(newDate) {
				const now = moment();
				const date = moment.tz(`${newDate} ${this.scheduledTime}`, "YYYY-MM-DD HH:mm", this.timeZone).local();

				if (date.isAfter(now)) {
					this.minimumTime = undefined;
				} else if (this.timeZone !== moment.tz.guess()) {
					this.minimumTime = now.tz(this.timeZone).format("HH:mm");
				} else {
					this.minimumTime = now.format("HH:mm");
				}

				this.populateTimes();
			},
			selectDate() {
				this.currentState = constants.SCHEDULER_STATE.SELECT_DATE;
			},
			dateSelected() {
				if (this.scheduledTime == "") {
					this.selectTime();
				} else {
					this.currentState = constants.SCHEDULER_STATE.SCHEDULE;
				}

				this.updateTemplate();
			},
			timeSelected() {
				this.updateTemplate();
			},
			getTeamMemberName() {
				let name = null;

				if (this.me?.user.firstname) {
					name = this.me?.user.firstname;
				}

				if (this.me?.user.lastname) {
					name = `${name} ${this.me?.user.lastname}`;
				}

				if (!name) {
					name = this.me?.user.email;
				}

				return name ?? "-";
			},
			updateTemplate() {
				if (!this.workingNotificationTemplate) {
					return;
				}

				const selectedDate = this.scheduledDate ?? this.currentDate;
				let selectedTime = this.scheduledTime;
				const timeZone = moment(selectedDate)
					.tz(this.timeZone ?? moment.tz.guess())
					.format("z");

				if (selectedTime == "") {
					selectedTime = moment().format("h:mm A");
				} else {
					let date = `${moment(selectedDate, "YYYY-MM-DD").format("MM/DD/YYYY")} ${selectedTime}`;
					selectedTime = moment(date).format("h:mm A");
				}

				const scheduledDate = `${moment(selectedDate, "YYYY-MM-DD").format(
					"M/DD/YYYY"
				)} ${selectedTime} ${timeZone}`;

				const teamMember = this.getTeamMemberName();
				const day = moment(selectedDate, "YYYY-MM-DD").format("dddd");
				const date = moment(selectedDate, "YYYY-MM-DD").format("M/D/YYYY");
				let template = this.workingNotificationTemplate;

				template = template
					.replace("${teamMember}", teamMember)
					.replace("${user}", teamMember)
					.replace("${organization}", this.organization.name)
					.replace("${day}", day)
					.replace("${date}", date)
					.replace("${time}", selectedTime)
					.replace("${timezone}", timeZone)
					.replace("${scheduledDate}", scheduledDate);

				const replaceIfChanged = (previousValue, currentValue, template) => {
					if (previousValue && previousValue !== currentValue) {
						template = template.replace(previousValue, currentValue);
						previousValue = currentValue;
					}
					return [previousValue, template];
				};
				[this.previousDay, template] = replaceIfChanged(this.previousDay, day, template);
				[this.previousDate, template] = replaceIfChanged(this.previousDate, date, template);
				[this.previousScheduledTime, template] = replaceIfChanged(
					this.previousScheduledTime,
					selectedTime,
					template
				);
				[this.previousTimeZone, template] = replaceIfChanged(this.previousTimeZone, timeZone, template);
				[this.previousScheduledDate, template] = replaceIfChanged(
					this.previousScheduledDate,
					scheduledDate,
					template
				);

				this.notificationTemplate = template;
			},
			confirmation() {
				this.currentState = constants.SCHEDULER_STATE.CONFIRMATION;
			},
			cardClass() {
				return `conversation-card ${DeviceUtility.isMobile ? "conversation-bottom" : ""} ${
					this.conversationType
				}`;
			},
			startButtonDisabled() {
				return (
					!this.scheduledDate ||
					!this.form ||
					!this.scheduledTime ||
					!this.validPhone ||
					!!this.actioningTextOverride
				);
			},
			close() {
				this.reset();
				if (DeviceUtility.isMobile) {
					if (!this.$route.query.from) {
						this.$router.push("/");
					} else {
						this.$router.go(-1);
					}
				}
				this.$emit("close-menu");
			},
			reset() {
				this.currentState = constants.SCHEDULER_STATE.SCHEDULE;
				this.meetingUrl = "";
				this.phone = "";
				this.email = "";
				this.emptyEmail = true;
				this.name = "";
				this.includeEmailInvitation = false;
				this.scheduledDate = moment().format("YYYY-MM-DD");
				this.currentPeriod = moment().format("a");
				this.scheduledTime = "";
				this.autoSetName = "";
				this.autoSetNameValue = "";
				this.notificationTemplate = this.organization.notificationTemplateScheduled;
				this.updateTemplate();
				setTimeout(() => {
					this.$refs?.phoneInput?.focus();
				}, 500);
			},
			emailChanged() {
				if (this.email && this.email.length > 0) {
					this.includeEmailInvitation = true;
				} else {
					this.includeEmailInvitation = false;
				}
			},
			countCharacters(value) {
				return value?.unicodeLength();
			},
			pasteEvent(event) {
				this.phone = event.clipboardData?.getData("text")?.replace(/\D/g, "");
				// If a user pastes into the vue-tel-input, force it to reformat itself.
				this.$nextTick(function () {
					try {
						this.$refs.phoneInput.onInput();
					} catch (e) {}
				});
			},
			// Fires when the correctness of the vue-tel-input's phone number changes (from true to false or vice-versa) and when the component is mounted.
			validate(args) {
				this.validPhone = args.valid;
			},
			// Fires when the vue-tel-input's input changes.
			async matchPhone(number, phoneObject) {
				if (!this.validPhone || !phoneObject || !phoneObject.valid) {
					// bail on invalid numbers
					if (!phoneObject || !phoneObject.valid) {
						this.setCustomer(null);
					}
					return;
				}

				// Make sure that even if the user changes the formatting of their phone number, we always internally
				// update it to formatted version. This way, when the phone number gets saved to the database, it is
				// always properly formatted.
				this.phone = phoneObject.formatted;

				if (this.phone == "") {
					this.setCustomer(null);
					return;
				}

				if (!number && this.autoSetName) {
					this.name = "";
				}
				const result = await api.getCustomers({
					where: {
						"$PhoneNumbers.phoneNumber$": {
							$like: `%${number}%`,
						},
					},
				});

				if (result.count > 0) {
					this.customers = result.rows;
					let customer = result.rows[0];
					const name = `${customer.firstname} ${customer.lastname}`.trim();
					customer = Object.assign({}, customer, { name });
					this.setCustomer(customer);
				} else {
					this.setCustomer(null);
				}
			},
			customerSelected(customer) {
				if (customer) {
					this.customerJustSelected = true;
				}

				this.setCustomer(customer);
			},
			customerPhoneSelected(customer) {
				this.phone = customer.phone;
				this.phoneSearchResultsOpen = false;
				this.customerJustSelected = true;
			},
			phoneBlur() {
				this.deactivateCustomerPhoneSearchMenu();
				this.showPhoneFieldErrorState = this.phone && !this.validPhone;
			},
			phoneFocus() {
				this.activateCustomerPhoneSearchMenu();
				this.showPhoneFieldErrorState = false;
			},
			activateCustomerPhoneSearchMenu() {
				if (this.phone.length > 0) {
					this.phoneSearchResultsOpen = true;
					this.$refs.customerPhoneSearch.activateMenu();
				}
			},
			deactivateCustomerPhoneSearchMenu() {
				this.phoneSearchResultsOpen = false;
			},
			activateTimePickerMenu() {
				this.$refs.timePicker.activateMenu();
			},
			async clearCustomer() {
				this.setCustomer(null);
				this.phone = "";
				this.customers = await Dialogs.getCustomers();
			},
			setCustomer(customer) {
				this.selectedCustomer = customer;
				if (customer) {
					this.validCustomer = true;
					this.name =
						customer.firstname && customer.lastname
							? customer.firstname + " " + customer.lastname
							: customer.firstname || customer.lastname;
					this.autoSetName = true;
					this.autoSetNameValue = this.name;

					if (customer.EmailAddresses?.length > 0) {
						this.email = customer.EmailAddresses[0].emailAddress;
						this.emailDisabled = true;
					} else {
						this.emailDisabled = false;
					}

					if (!this.phone && customer.PhoneNumbers?.length > 0) {
						this.phone = customer.PhoneNumbers[0].phoneNumber;
					}
				} else {
					this.validCustomer = false;
					this.emailDisabled = false;
					this.name = "";
					this.email = "";
				}
			},
			templateChanged(template) {
				// Reset notification template
				if (template == "") {
					this.notificationTemplate = this.defaultNotificationTemplate;
					this.workingNotificationTemplate = this.notificationTemplate;
					this.updateTemplate();
				}
			},
			loadTeamMembers() {
				this.teamMembers = [];
				api.getSharingUsers().then((result) => {
					const role = this.me?.account.roles[0].name.toLowerCase();
					if (role === "user") {
						result.forEach((user) => {
							this.teamMembers.push(user);
						});
					} else if (role === "administrator") {
						this.teamMembers = result;
					}
				});
			},
			async schedule() {
				if (!this.validPhone || !this.phone || !this.scheduledDate || !this.scheduledTime) {
					return;
				}

				this.saving = true;

				try {
					let baseUrl = window.env.VITE_RAPID_DOMAIN;
					if (this.conversationType === "async") {
						baseUrl = window.env.VITE_ASYNC_DOMAIN;
					}

					const me = await api.getMe();
					const selectedUserId = me.user.id;
					const nameParts = this.name.trim().split(" ");
					const first = nameParts.length > 0 ? nameParts[0] : "";
					const last = nameParts.length > 1 ? nameParts.slice(1).join(" ") : "";

					console.log(first, last);
					const userDate = moment.tz(
						`${this.scheduledDate} ${this.scheduledTime}`,
						"YYYY-MM-DD hh:mm A",
						this.timeZone
					);
					if (this.newSelectedProject && this.selectedCustomer?.id) {
						const selectedContact = this.selectedCustomer;
						let contactExistance = false;
						if (!this.newSelectedProject.Customers) {
							this.newSelectedProject.Customers = [];
						}
						for (const contact of this.newSelectedProject.Customers) {
							if (contact.id === selectedContact.id) {
								contactExistance = true;
								break;
							}
						}
						if (!contactExistance) {
							await api.updateProject(this.newSelectedProject.id, {
								Customers: [...this.newSelectedProject.Customers, selectedContact],
							});
						}
					}
					let startAt = userDate.unix() * 1000;

					const data = {
						appointment: {
							customerId: this.selectedCustomerId ?? undefined,
							userId: selectedUserId,
							startAt: startAt,
							timeZone: this.timeZone,
						},
						conversation: {
							referrer: baseUrl,
							autoStartRecordings: this.organization.autoStartRecordings,
							forcedUserId: selectedUserId,
							customerId: this.selectedCustomerId ?? undefined,
							notificationSMS: false,
							notificationTemplate: this.notificationTemplate?.trim(),
							screenShareSupported: this.conversationType != constants.CONVERSATION_TYPE.ASYNC,
							visitors: [
								{
									phone: this.phone.trim(),
									email: this.email.trim(),
									firstname: first,
									lastname: last,
									notificationSMS: true,
									notificationEmail: this.includeEmailInvitation,
								},
							],
							shareUserId: this.shareUser ? this.shareUser.id : undefined,
							type: this.conversationType,
							initiationType: "admin",
							projectId: this.newSelectedProject ? this.newSelectedProject.id : undefined,
							browserId: tokenHandler.getBrowserId(),
							browserToken: await tokenHandler.getOrRefreshToken(),
						},
					};

					// save data
					const result = await api.createAppointment(data);

					if (result.appointment?.id) {
						this.appointment = result.appointment;
						this.conversation = result.conversation;
						this.appointment.icsText = result.icsText;
						this.appointment.googleCalendarUrl = result.googleCalendarUrl;
						this.confirmation();
						EventBus.$emit("UpdateDashboard");
					}
				} catch (e) {
					console.error(e);
				}

				this.saving = false;
			},
			join() {
				window.open(this.conversation.creatorUrl, "_blank");
			},
		},
		watch: {
			searchCustomers(searchTerm) {
				clearTimeout(this._searchTimerId);
				this._searchTimerId = setTimeout(async () => {
					const customers = await Dialogs.getCustomers(searchTerm);
					this.customers = customers;
				}, 500);
			},
			scheduledDate: {
				handler(newDate, oldDate) {
					this.previousScheduledDate = oldDate;
					this.previousDay = moment(oldDate, "YYYY-MM-DD").format("dddd");
					this.previousDate = moment(oldDate, "YYYY-MM-DD").format("M/D/YYYY");
					this.previousTimeZone = moment(oldDate)
						.tz(this.timeZone ?? moment.tz.guess())
						.format("z");
					this.updateMinimumDate(newDate);
					this.updateTemplate();
				},
			},
			scheduledTime: {
				handler(newTime, oldTime) {
					let date = `${moment(this.scheduledDate, "YYYY-MM-DD").format("MM/DD/YYYY")} ${oldTime}`;
					this.previousScheduledTime = moment(date).format("h:mm A");
					this.updateTemplate();
				},
			},
			notificationTemplate: {
				handler(newText, oldText) {
					this.workingNotificationTemplate = newText;
					//console.log(`Template Updated: Old=${oldText}, New=${newText}`);
				},
			},
			phone: {
				handler(newValue) {
					if (!newValue) {
						this.deactivateCustomerPhoneSearchMenu();
						return;
					}

					this.phoneSearch = newValue;

					if (this.customerJustSelected) {
						// The user just selected one of search results so we don't want to do a search/open the menu

						this.customerJustSelected = false;
						return;
					}

					clearTimeout(this._searchTimerId);
					this._searchTimerId = setTimeout(async () => {
						const customers = await Dialogs.getCustomers(newValue);
						console.log(customers);
						this.customers = customers;

						if (customers.length > 0) {
							this.activateCustomerPhoneSearchMenu();
						} else if (!newValue) {
							this.deactivateCustomerPhoneSearchMenu();
						}
					}, 500);
				},
			},
		},
	};
</script>

<style lang="scss" scoped>
	.start-conversation-button {
		font-family: "Inter Tight", sans-serif;
		font-size: 20px;
		color: white;
		margin-left: 10px;
	}
	.reset-span {
		position: relative;
		left: calc(100% - 50px);
		display: inline-block;
		top: -30px;
		height: 0;
	}

	.conversation-card {
		border-radius: 12px;
		min-height: 400px;
		display: flex;
		flex-direction: column;
		justify-content: start;
	}

	.conversation-card .header {
		display: flex;
		margin-bottom: 28px;
		margin-top: 12px;
		color: #000;
	}

	.conversation-card .header.schedule {
		justify-content: space-between;
	}

	.conversation-card .header.schedule .v-btn {
		background-color: #fff;
		border: 1px solid #03011d;
		color: #03011d;
		font-weight: 600;
		padding: 0 6px;
	}

	.conversation-card .header.schedule .v-btn.open {
		background-color: #dff0ff;
	}

	.conversation-card textarea {
		color: #212121;
		font-size: 16px;
	}

	.conversation-card h2,
	.conversation-card i {
		font-size: 24px;
		margin-right: 5px;
	}

	.conversation-card .v-card__actions {
		padding: 16px;
		display: flex;
		justify-content: space-evenly;
	}

	.conversation-card .v-card__actions > button {
		width: 50%;
		height: 50px;
		border-radius: 25px;
		line-height: 20px;
		font-size: 1em;
		font-weight: 600;
	}

	.conversation-card .v-card__actions > button.back {
		width: 100%;
	}

	.conversation-card .auto-message {
		color: #b3b3b3;
		font-size: 16px;
	}

	.conversation-card .record-switch {
		margin-left: 5px;
		margin-top: 0px;
	}

	.conversation-card .v-card__actions > button:nth-child(2) {
		display: block;
		border: 1px solid #e0e0e0 !important;
	}

	.conversation-card .contact-name.reset {
		padding-top: 0px;
	}

	.field-border-radius {
		border-radius: 6px;
	}

	.v-slide-group__next,
	.v-slide-group__prev {
		display: none;
	}

	/* Email Invite */

	.date-container {
		display: flex;
		margin-bottom: 16px;
	}

	.date-container .time-pill {
		position: relative;
	}

	.date-container .btn-pill {
		width: 100%;
	}

	.date-container .btn-pill:first-child {
		margin-right: 12px;
	}

	.date-container::v-deep .v-menu__content,
	.v-menu__content {
		max-height: 300px !important;
	}

	.team-member-share {
		position: absolute;
		padding-top: 8px;
		margin-left: 2px;
		color: #0070ff;
	}

	.confirmation .schedule-header {
		text-align: center;
	}

	.confirmation hr {
		margin: 16px 0;
		color: #dfe0e2;
	}

	.confirmation .schedule-title {
		margin: 12px 0;
		background: linear-gradient(93deg, #019c9c 0%, #01bcbc 27.62%);
		background-clip: text;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}

	.confirmation {
		color: #03011d;
		font-size: 1em;
	}

	.confirmation .time {
		margin-bottom: 8px;
	}

	.confirmation .time,
	.confirmation .timezone {
		line-height: 1.5;
	}

	.confirmation .schedule {
		padding: 0 24px;
		color: #9b9ea5;
	}

	.confirmation .schedule .icon {
		color: #0070ff;
	}

	.confirmation .summary {
		font-size: 0.875em;
		padding: 0 24px;
		margin-bottom: 16px;
		text-align: center;
	}

	.confirmation .calendar-links {
		font-size: 0.875em;
		font-weight: 600;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.confirmation .calendar-links .link {
		display: flex;
		margin-bottom: 16px;
		align-items: center;
		align-self: stretch;
		color: #03011d77;
	}

	.confirmation .calendar-links .link img {
		margin-right: 20px;
	}

	.confirmation .calendar-links .link:nth-child(3) {
		margin-bottom: 0px;
	}

	.customer-phone-search {
		position: absolute;
		top: 0;
		left: 0;
		visibility: hidden;
	}

	.invalid-date {
		color: #cf2546;
		font-size: 0.75rem;
	}
</style>
<style>
	.time-picker-menu {
		max-height: 400px !important;
	}

	.v-time-picker-clock__ampm .v-picker__title__btn:hover {
		cursor: pointer;
	}

	.v-time-picker-clock.theme--light {
		background: #dff0ff;
	}

	.v-time-picker-clock__item {
		color: #5e5c6e;
	}

	.v-time-picker-clock__item.v-time-picker-clock__item--active {
		color: white;
	}

	.vue-tel-input .vti__input {
		border-radius: 0 11px 11px 0;
	}
</style>
