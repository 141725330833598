<template>
	<div>
		<v-menu v-model="menu" :close-on-content-click="false" offset-y>
			<template v-slot:activator="{ on, attrs }">
				<v-chip v-bind="attrs" v-on="on" :disabled="true" @click="menu = !menu">
					{{ getSelectedLabel }}
				</v-chip>
			</template>
			<v-list>
				<v-list-item v-for="item in items" :key="item.value" :disabled="true" @click="selectItem(item.value)">
					<v-list-item-title>{{ item.label }} </v-list-item-title>
				</v-list-item>
			</v-list>
		</v-menu>
	</div>
</template>

<script>
	import { AccessType } from "@/enums/AccessType";

	export default {
		props: {
			value: {
				type: String,
				required: true,
			},
			items: {
				type: Array,
				default: () =>
					Object.keys(AccessType).map((key) => ({
						label: key,
						value: AccessType[key],
					})),
			},
		},
		data() {
			return {
				menu: false,
			};
		},
		computed: {
			getSelectedLabel() {
				const selectedItem = this.items.find((item) => item.value === this.value);
				return selectedItem ? selectedItem.label : "";
			},
		},
		methods: {
			selectItem(value) {
				this.$emit("input", value);
				this.$emit("change", value);
				this.menu = false;
			},
		},
	};
</script>
