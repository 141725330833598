<template>
	<div>
		<v-dialog :disabled="isDisabled" v-model="dialog" width="400">
			<template v-slot:activator="{ on, attrs }">
				<v-btn icon dark v-bind="attrs" v-on="on" @click="init">
					<v-icon :disabled="isDisabled">icon-share-contact-stroke</v-icon>
				</v-btn>
			</template>

			<v-card>
				<v-card-title class="d-flex text-h5 mb-2">
					<div class="d-flex justify-space-between grow header">
						<v-icon :disabled="isDisabled">icon-share-contact-stroke</v-icon>
						<h5>Share With Others</h5>
						<v-icon @click="copyLink">icon-link-double</v-icon>
					</div>
				</v-card-title>

				<v-card-text>
					<v-autocomplete
						ref="customerSearch"
						class="mb-0 text--red"
						hide-details
						:append-icon="searchText && searchedMembers.length == 0 ? `icon-plus` : ``"
						@click:append="createNewContact"
						outlined
						label="People"
						chips
						deletable-chips
						no-data-text="Start typing to find people"
						counter="60"
						multiple
						@update:search-input="onChangeInput"
						@change="onChange"
						:search-input.sync="searchText"
						v-model="shareUsers"
						item-text="formattedName"
						item-value="id"
						:hide-no-data="true"
						:menu-props="{ width: '100px' }"
						:items="memberItems"
						return-object
						prepend-inner-icon="icon-user-single"
						:maxLength="60">
						<template v-slot:item="{ item }">
							<v-list-item-content class="min-wid" v-if="!item.edit">
								<span v-if="item.name">{{ item.name }}</span>
								<span v-else>{{ item.email }}</span>
								<v-list-item-subtitle
									><span v-if="item.phone">{{ item.phone }}</span></v-list-item-subtitle
								>
							</v-list-item-content>
						</template>
					</v-autocomplete>
					<div v-if="searchText && searchedMembers.length == 0" class="text-small ml-4 text-blue">
						To share with new people, create a contact. A contact must have an email address to be given
						“Editor” or “Contributor” access.
					</div>

					<v-select
						outlined
						hide-details
						id="acces-type"
						class="status-input mb-0 mt-4"
						v-model="accessType"
						:disabled="true"
						:active="false"
						item-text="label"
						item-value="value"
						chips
						:items="[{ label: 'Viewer', value: 'viewer' }]">
						<template v-slot:label>
							<div class="label">Access Type</div>
						</template>
						<template v-slot:append>
							<v-icon>icon-lock</v-icon>
						</template>
					</v-select>

					<div class="text-small mb-4 ml-4 text-start">
						People with “Viewer” access will be able to view files, but will not be able to make any
						changes, or upload content.
					</div>
					<div>
						<div class="text-h6 font-weight-bold mb-4 text-start">People With Access</div>
						<div class="d-flex flex-column" v-for="item in paginatedUsers" :key="item.id">
							<div class="d-flex justify-content-between">
								<div class="flex-grow-1 overflow-hidden">
									<div class="font-weight-bold mt-4 text-truncate">{{ getTitle(item) }}</div>
									<div class="text-small text-truncate">{{ getSubtitle(item) }}</div>
								</div>
								<div class="flex-shrink-0 ms-3 center mt-2 mb-2">
									<AccessTypeMenu
										v-model="item.accessType"
										@change="(value) => handleOptionChange(item, value)" />
								</div>
							</div>
							<v-divider></v-divider>
						</div>
						<v-pagination v-model="currentPage" :length="pageCount" :total-visible="5" class="mt-2" />
					</div>
				</v-card-text>
				<CopyLinkSnackBar
					:link-copied="linkCopied"
					:share-text="shareText"
					:share-url="shareUrl"
					:close-dialog="closeCopyLinkDialog" />
				<v-card-actions>
					<v-btn large rounded elevation="0" @click="dialog = false"> Back </v-btn>
					<v-btn large rounded elevation="0" :disabled="shareUsers.length == 0" color="primary" @click="send">
						Share</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<CreateCustomerDialog ref="CreateCustomerDialogRef" :name="searchText"></CreateCustomerDialog>
	</div>
</template>
<script>
	import api from "../api";
	import uuid from "../helpers/uuid.js";
	import AccessTypeMenu from "./AccessTypeMenu.vue";
	import { hasFreeTrialEnded } from "../helpers/hasFreeTrialEnded.js";
	import { AccessType } from "@/enums/AccessType";
	import CreateCustomerDialog from "./CreateCustomerDialog.vue";
	import * as Sentry from "@sentry/vue";
	import CopyLinkSnackBar from "./CopyLinkSnackBar.vue";
	import DeviceUtility from "@/helpers/device";

	const DELETED_USER = ".";
	export default {
		name: "ShareActionDialog",
		props: {
			projectId: String,
			projectName: String,
			isDisabled: Boolean,
		},
		components: {
			AccessTypeMenu,
			CreateCustomerDialog,
			CopyLinkSnackBar,
		},
		data() {
			return {
				dialog: false,
				shareUsers: [],
				sharedUsers: [],
				teamMembers: [],
				searchedMembers: [],
				searchText: "",
				customers: [],
				me: null,
				accessType: AccessType.Viewer,
				currentPage: 1,
				itemsPerPage: 5,
				currentSearchPage: 1,
				linkCopied: false,
				shareUrl: "",
				shareText: "",
				project: null,
				createdByUser: null,
			};
		},
		async mounted() {},
		methods: {
			validate(v) {
				for (const item of v) {
					if (!item.email) {
						return "Email address is required.";
					}
					if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(item.email)) {
						return "Email address must be valid.";
					}
				}
				return true;
			},
			closeCopyLinkDialog() {
				this.linkCopied = false;
			},
			async createNewContact() {
				this.$refs.customerSearch.menuIsActive = false;
				try {
					const record = await this.$refs.CreateCustomerDialogRef.open();
					if (record) {
						await this.loadCustomers();
						const customer = this.getCustomerDetails(record);
						this.addToShareUsers(customer);
						this.$refs.customerSearch.menuIsActive = true;
						this.checkDirty();
					}
				} catch (err) {
					Sentry.captureException(err, {
						tags: {
							method: "createNewCustomer",
							file: "ShareAction",
						},
					});
				}
			},
			addToShareUsers(item) {
				this.shareUsers.push(item);
			},
			async getShareUrlOfProject() {
				if (!this.project.shareId) {
					// create a uuid for sharing
					const shareId = uuid.uuidv4();
					this.project = await api.updateProject(this.projectId, {
						shareId,
						genericAccessType: AccessType.Viewer,
					});
				}
				return this.project.shareUrl;
			},
			onChange(items) {
				this.searchText = "";
				this.shareUsers = items.filter((item) => typeof item === "object");
				return;
			},
			onChangeInput(searchText) {
				this.searchedMembers = [];
				if (!searchText) {
					this.currentSearchPage = 1;
					return;
				}
				this.searchText = searchText;
				this.memberItems.filter((member) => {
					if (member.name.toLowerCase().includes(searchText.toLowerCase())) {
						if (!this.sharedUsers.find((u) => u.userIdentifier == member.email)) {
							this.searchedMembers.push(member);
						}
					}
				});
			},
			async init() {
				this.me = await api.getMe(true, true, true);
				if (await hasFreeTrialEnded(this.me.organization)) {
					this.dialog = false;
					this.$root.$freeTrialEndedDialog.open(this.me.organization);
				} else {
					this.project = await api.getProject(this.projectId);
					this.createdByUser = await api.getUser(this.project.createdBy);
					await this.loadTeamMembers();
					await this.loadCustomers();
					await this.loadSharedUsers();
					this.shareUrl = await this.getShareUrlOfProject();
				}
			},
			getTitle(item) {
				let appendText = "";
				if (item.userIdentifier == this.createdByUser.Account.id) {
					appendText = " (Creator)";
				} else if (item.userIdentifier == this.me.account.id || item.userIdentifier == this.me.user.email) {
					appendText = " (You)";
				}
				return this.getTitleList(item)[0] + appendText;
			},
			getTitleList(item) {
				const finalTitles = [];
				if (item.userIdentifierType === "account") {
					const id = item.userIdentifier;
					const user = this.teamMembers.find((u) => u.Account?.id === id);
					if (user) {
						finalTitles.push(`${user.firstname} ${user.lastname}`);
					} else {
						finalTitles.push(DELETED_USER);
					}
				}
				if (item.User?.name) {
					finalTitles.push(item.User.name);
				}
				if (item.User?.email) {
					finalTitles.push(item.User.email);
				}
				if (item.User?.phone) {
					finalTitles.push(item.User.phone);
				}
				if (item.Customer?.name) {
					finalTitles.push(item.Customer.name);
				}
				if (item.EmailAddress) {
					finalTitles.push(item.EmailAddress.emailAddress);
				}
				if (item.Customer?.EmailAddresses && item.Customer.EmailAddresses.length > 0) {
					finalTitles.push(item.Customer.EmailAddresses[0]?.emailAddress);
				}
				if (item.Customer?.PhoneNumbers && item.Customer.PhoneNumbers.length > 0) {
					finalTitles.push(item.Customer.PhoneNumbers[0]?.phoneNumber);
				}
				if (item.userIdentifierType === "email") {
					const customer = this.customers.find(
						(c) => c.EmailAddresses[0]?.emailAddress === item.userIdentifier
					);
					if (customer) {
						finalTitles.push(customer.name);
						if (customer.EmailAddresses && customer.EmailAddresses.length > 0) {
							finalTitles.push(customer.EmailAddresses[0]?.emailAddress);
						}
						if (customer.PhoneNumbers && customer.PhoneNumbers.length > 0) {
							finalTitles.push(customer.PhoneNumbers[0]?.phoneNumber);
						}
					}
				}
				if (finalTitles.length === 0) {
					finalTitles.push(DELETED_USER);
				}
				return finalTitles;
			},
			async handleOptionChange(item, value) {
				await api.updateProjectShare(item.id, { accessType: value });
			},
			getSubtitle(item) {
				let subtitleList = this.getTitleList(item);
				const subtitleListSet = new Set(subtitleList);
				subtitleList = Array.from(subtitleListSet);
				// take index 1 and 2 and join them
				return subtitleList.slice(1).join(" | ");
			},
			async loadTeamMembers() {
				this.teamMembers = [];
				const result = await api.getUsers();
				result.rows.forEach((account) => {
					this.teamMembers.push(account);
				});
			},
			async loadCustomers() {
				this.customers = [];
				const result = await api.getCustomers({ pageSize: 500, page: 1 });
				this.customers = result.rows;
			},
			loadSharedUsers() {
				api.getProjectShares({ where: { projectId: this.projectId }, pageSize: 1000 }).then((result) => {
					this.sharedUsers = this.sortUsers(result.rows.filter((user) => !!user)).filter((user) => {
						return !this.getTitle(user).startsWith(DELETED_USER);
					});
				});
			},
			sortUsers(users) {
				const projectCreator = users.find((u) => u.userIdentifier == this.createdByUser.Account.id);
				const myUser = users.find(
					(u) => u.userIdentifier == this.me.account.id || u.userIdentifier == this.me.user.email
				);
				// keep project creator and my user at the top, rest sorted alphabetically
				const usersList = projectCreator ? [projectCreator] : [];
				if (this.me.account.id != this.createdByUser.Account.id && myUser) {
					usersList.push(myUser);
				}
				return [
					...usersList,
					...users
						.filter(
							(u) =>
								u.userIdentifier != this.createdByUser.Account.id &&
								u.userIdentifier != this.me.account.id
						)
						.sort((a, b) => {
							const nameA = this.getTitle(a);
							const nameB = this.getTitle(b);
							return nameA.localeCompare(nameB);
						}),
				];
			},
			getCustomerDetails(customer) {
				if (!customer.EmailAddresses || customer.EmailAddresses.length == 0) {
					if (customer.PhoneNumbers && customer.PhoneNumbers.length > 0) {
						return {
							id: customer.id,
							name: customer.name,
							formattedName:
								customer.name.length > 0 ? customer.name : customer.PhoneNumbers[0]?.phoneNumber,
							phone: customer.PhoneNumbers[0]?.phoneNumber,
						};
					}
					return;
				}
				return {
					id: customer.id,
					name: customer.name,
					formattedName: customer.name.length > 0 ? customer.name : customer.EmailAddresses[0]?.emailAddress,
					email: customer.EmailAddresses[0]?.emailAddress,
					phone: customer.PhoneNumbers[0]?.phoneNumber,
				};
			},
			async copyLink() {
				if (DeviceUtility.isTablet || DeviceUtility.isMobile) {
					this.$emit("copy-link");
					return;
				}
				if (navigator.clipboard) {
					try {
						this.shareUrl = await this.getShareUrlOfProject();
						await navigator.clipboard.writeText(this.shareUrl);
					} catch (err) {
						console.error("error copying link", err);
					}
				}
				this.shareText = `The project link has been saved to your clipboard. Some devices limit your ability to copy to the clipboard. In that case, you can copy the link below.`;
				this.linkCopied = true;
			},
			async send() {
				try {
					const promises = [];
					const usersWithoutPhone = this.shareUsers.filter((user) => !user.phone);
					if (usersWithoutPhone.length > 0) {
						const userStringWithoutPhone = usersWithoutPhone.map((user) => user.name).join("<br>");
						if (
							!(await this.$root.$confirm.open(
								"Heads up! The following people do not have phone numbers:",
								`${userStringWithoutPhone}<br><br>If they have a LiveSwitch Contact
		                               account, they will be able to see the project in their Projects table but will
		                                not receive a text message informing them they have been added to the project.<br>
		                                If they do not have a LiveSwitch Contact account, add a phone number to the
		                                 contact record, and then share the project. Alternatively, you can copy the project link below and send it to them. <br><br> <a href=${this.shareUrl} target="_blank">${this.shareUrl}</a>`,
								{},
								false,
								"Share",
								"Back",
								true
							))
						) {
							return;
						}
					}
					for (let user of this.shareUsers) {
						if (!user.email) {
							const request = {
								projectId: this.projectId,
								userIdentifier: user.id,
								userIdentifierType: "customer",
								accessType: this.accessType,
							};
							promises.push(api.createProjectShare(request));
							continue;
						}
						if (this.sharedUsers.find((u) => u.userIdentifier == user.email)) {
							continue;
						}
						const request = {
							projectId: this.projectId,
							userIdentifier: user.email,
							userIdentifierType: "email",
							accessType: this.accessType,
						};
						promises.push(api.createProjectShare(request));
					}
					this.shareUsers = [];
					await Promise.all(promises);
					this.dialog = false;
					this.$emit("shared");
				} catch (err) {
					console.error("error sharing", err);
				}
			},
		},
		computed: {
			paginatedUsers() {
				const start = (this.currentPage - 1) * this.itemsPerPage;
				const end = start + this.itemsPerPage;
				return this.sharedUsers.slice(start, end);
			},
			pageCount() {
				return Math.ceil(this.sharedUsers.length / this.itemsPerPage);
			},
			paginatedSearchUsers() {
				const start = (this.currentSearchPage - 1) * this.itemsPerPage;
				const end = start + this.itemsPerPage;
				return this.searchedMembers.slice(start, end);
			},
			pageSearchCount() {
				return Math.ceil(this.searchedMembers.length / this.itemsPerPage);
			},
			memberItems() {
				let team = [];
				team.push(
					...this.teamMembers.map((user) => {
						let name = `${user.firstname} ${user.lastname}`.trim();
						const email = user.email;
						return {
							id: user.Account.id,
							name,
							formattedName: name.length > 0 ? name : email,
							email,
							phone: user.phone,
						};
					})
				);
				this.customers.forEach((customer) => {
					if (!customer.EmailAddresses || customer.EmailAddresses.length == 0) {
						if (customer.PhoneNumbers && customer.PhoneNumbers.length > 0) {
							team.push({
								id: customer.id,
								name: customer.name,
								formattedName:
									customer.name.length > 0 ? customer.name : customer.PhoneNumbers[0]?.phoneNumber,
								phone: customer.PhoneNumbers[0]?.phoneNumber,
							});
						}
						return;
					}
					team.push({
						id: customer.id,
						name: customer.name,
						formattedName:
							customer.name.length > 0 ? customer.name : customer.EmailAddresses[0]?.emailAddress,
						email: customer.EmailAddresses[0]?.emailAddress,
						phone: customer.PhoneNumbers[0]?.phoneNumber,
					});
				});
				if (this.sharedUsers) {
					team = team.filter((member) => {
						return !this.sharedUsers.find(
							(u) => u.userIdentifier == member.email || u.userIdentifier == member.id
						);
					});
				}
				return team;
			},
		},
	};
</script>
<style scoped>
	.flex-grow-1 {
		flex-grow: 1;
	}

	.min-width-0 {
		min-width: 0;
	}

	.text-ellipsis {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	.v-card__title .v-icon.icon-share-contact-stroke {
		width: 40px;
		height: 40px;
		margin-right: 12px;
		border-radius: 50%;
		background-color: #dff0ff;
	}

	.v-card__actions .v-btn {
		flex: 1;
	}

	.text-small {
		font-size: 10px;
		max-width: 100%;
		inline-size: inherit;
		overflow-wrap: break-word;
		line-height: 1.5;
	}

	.min-wid {
		max-width: 300px !important;
	}

	.text-blue {
		color: #2d74ff;
	}

	.text-medium {
		font-size: 10px;
		font-weight: 900;
	}

	.v-card {
		border-radius: 20px;
	}

	.header {
		align-items: center;
		max-width: 100%;
	}

	.v-snack__wrapper.theme--dark {
		background-color: #fff !important;
	}

	.theme--dark.v-sheet {
		background-color: #fff !important;
	}
	.copied-bubble {
		background-color: #fff;
		color: #000;
	}

	.v-select__selection,
	.v-select__selection--comma,
	.v-select.v-text-field input {
		color: blue !important;
	}

	.copy-recording-link {
		margin-right: 8px;
	}

	.v-autocomplete__selection,
	.v-autocomplete__selection--comma,
	.v-autocomplete.v-text-field input {
		color: blue !important;
	}

	.label {
		pointer-events: none;
		color: black;
		cursor: not-allowed;
		background-image: none;
		background-color: #fff;
		border-color: #193e63;
	}
</style>
